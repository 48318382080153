import React, { useState } from "react";
import Slider from "./Slider";
// import MarqueeLComList from "./MarqueeLComList";
// import { BackTop } from "antd";
import '../../../App.scss'

import img3 from "../../../Images/slider/3.jpg";
import img4 from "../../../Images/slider/4.jpg";
import img5 from "../../../Images/slider/5.jpg";
import img6 from "../../../Images/slider/6.jpg";

import logo1 from "../../../Images/companylogo/1.png";
import logo2 from "../../../Images/companylogo/2.png";
import logo3 from "../../../Images/companylogo/3.png";
import logo4 from "../../../Images/companylogo/4.png";
import logo5 from "../../../Images/companylogo/5.png";
import logo6 from "../../../Images/companylogo/6.png";
import logo7 from "../../../Images/companylogo/7.png";
import logo8 from "../../../Images/companylogo/8.png";
import logo9 from "../../../Images/companylogo/9.png";
import logo10 from "../../../Images/companylogo/10.png";
import logo11 from "../../../Images/companylogo/11.png";
import logo12 from "../../../Images/companylogo/12.png";
import logo13 from "../../../Images/companylogo/13.png";
import logo14 from "../../../Images/companylogo/14.png";
import logo15 from "../../../Images/companylogo/15.png";
import logo16 from "../../../Images/companylogo/16.png";
import logo17 from "../../../Images/companylogo/17.png";
import logo18 from "../../../Images/companylogo/18.png";
import logo19 from "../../../Images/companylogo/19.png";
import logo20 from "../../../Images/companylogo/20.png";
import logo21 from "../../../Images/companylogo/21.png";
import logo22 from "../../../Images/companylogo/22.png";
import logo23 from "../../../Images/companylogo/23.png";
import logo24 from "../../../Images/companylogo/24.png";
import logo25 from "../../../Images/companylogo/25.png";
import logo26 from "../../../Images/companylogo/26.png";
import logo27 from "../../../Images/companylogo/27.png";
import logo28 from "../../../Images/companylogo/28.png";
import logo29 from "../../../Images/companylogo/29.png";
import logo30 from "../../../Images/companylogo/30.png";
import logo31 from "../../../Images/companylogo/31.png";
import logo32 from "../../../Images/companylogo/32.png";
import logo33 from "../../../Images/companylogo/33.png";
import logo34 from "../../../Images/companylogo/34.png";
import logo35 from "../../../Images/companylogo/35.png";
import logo36 from "../../../Images/companylogo/36.png";
import logo37 from "../../../Images/companylogo/37.png";
import logo38 from "../../../Images/companylogo/38.png";
import logo39 from "../../../Images/companylogo/39.png";
import logo40 from "../../../Images/companylogo/40.png";
import logo41 from "../../../Images/companylogo/41.png";
import logo42 from "../../../Images/companylogo/42.png";
import logo43 from "../../../Images/companylogo/43.png";
import logo44 from "../../../Images/companylogo/44.png";
import logo45 from "../../../Images/companylogo/45.png";
// import logo46 from "../../../Images/companylogo/46.png";
import logo47 from "../../../Images/companylogo/47.png";
import logo48 from "../../../Images/companylogo/48.png";
import logo49 from "../../../Images/companylogo/49.png";
import logo50 from "../../../Images/companylogo/50.png";
import logo51 from "../../../Images/companylogo/51.png";
import logo52 from "../../../Images/companylogo/52.png";
import logo53 from "../../../Images/companylogo/53.png";
import logo54 from "../../../Images/companylogo/54.png";
import logo55 from "../../../Images/companylogo/55.png";
import LogoCarousel from "./LogoCarousel";

const AllCompanyLogos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
  logo25,
  logo26,
  logo27,
  logo28,
  logo29,
  logo30,
  logo31,
  logo32,
  logo33,
  logo34,
  logo35,
  logo36,
  logo37,
  logo38,
  logo39,
  logo40,
  logo41,
  logo42,
  logo43,
  logo44,
  logo45,
  // logo46,
  logo47,
  logo48,
  logo49,
  logo50,
  logo51,
  logo52,
  logo53,
  logo54,
  logo55,
]

const HomeComponent = () => {
  const [allImages, setAllImages] = useState([
    img3,
    img4,
    img5,
    img6,
  ]);

  return (
    <div className="home-component w-100 p-0 pb-5 mb-5">
      <div className="row w-100 no-background p-0">
        <div className="col-md-12 p-0">
          <Slider allImages={allImages} height={500} />
        </div>
      </div>

      <div className="company-info-container py-5">
        <div className="company-info container mt-4 p-3 pb-4">
          <h2 className="text-center w-100 pb-3">COMPANY PROFILE</h2>

          <p className="content pb-4">
            Founded in November 2014, <b>Krishna Associates</b> is professionally managed pharmaceutical distributors based in Belgaum (Karnataka) having 
            stockistship of more than 200 companies/divisions providing service to 1800 chemists / Institutions in Karnataka, Goa & Maharashtra. <b>Krishna 
            Associates</b> has a basket of around 7000 brands/formulations covering all major segments including Antibiotics, Anesthetics, Anti-HIV, Cardio-
            Diabetic, Derma, Hormones, Large Volume Parenteral-IV Fluids, Neuropsychiatry, Nutrition, Parenteral Nutrition, Pediatrics, Schedule-X-Narcotics and Vaccines etc.
          </p>

          <p className="d-flex justify-content-center w-100">
            <a href='/#AboutUs' className="d-block btn btn-danger">Read More</a>
          </p>
        </div>
      </div>

      <div className="logo-slider pb-5">
        <LogoCarousel AllCompanyLogos={AllCompanyLogos} />
      </div>
    </div>
  );
};

export default HomeComponent;
