import React from "react";
import { useState } from "react";
import LoaderTri from "../../components/Loader/LoaderTri";
import { Image } from "antd";
import { Company } from "../../assets/Data";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d245619.6783370742!2d74.508441!3d15.866713!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbf669f5095362f%3A0x7e34b31edcdefb5f!2sBelagavi%2C%20Karnataka%2C%20India!5e0!3m2!1sen!2sau!4v1701172422430!5m2!1sen!2sau" 
            width="100%" 
            height="450" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="my-5 mx-4 contact-info">
            <p
              className="fw-bold m-0"
              dangerouslySetInnerHTML={{ __html: Company[0].address[1] }}
            ></p>
          </div>

          <div className="contact mx-4">
            {Company[0].phone.alternate.length > 0 &&
              Company[0].phone.alternate.map((item, index) => (
                <p className="fw-bold m-0" key={index}>
                  {index === 0 ? "Ph.: " : ""}
                  {item.label} {item.number}
                </p>
              ))}

            {Company[0].phone.primary.length > 0 &&
              Company[0].phone.primary.map((item, index) => (
                <p className="fw-bold m-0" key={index}>
                  {index === 0 ? "Mob.: " : ""}
                  {item.label} {item.number}
                </p>
              ))}

            {Company[0].email != "" && (
              <p className="fw-bold m-0">Email: {Company[0].email}</p>
            )}

            {
              Company[0].website != "" && (
              <p className="fw-bold m-0">Website: {Company[0].website}</p>
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
