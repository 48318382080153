import React, { useState } from "react";
import Slider from "../Home/components/Slider";
import img1 from "../../Images/slider2/1.jpg";
import img2 from "../../Images/slider2/2.jpg";
import img3 from "../../Images/slider2/3.jpg";
import img4 from "../../Images/slider2/4.jpg";
import img5 from "../../Images/slider2/5.jpg";
import img6 from "../../Images/slider2/6.jpg";

const OurVision = () => {

    const [allImages, setAllImages] = useState([
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
    ]);

    return (
        <section className="container p-0 pb-5">
            <div className="col-md-12 p-0 w-100">
                <Slider allImages={allImages} height={300} />
            </div>

            <div className="px-2">
                <h1 className="mt-5 mb-4">OUR VISION</h1>

                <p>Our vision is to be a leading pharmaceutical distributor in upcoming years having a hub for every category of pharmaceutical formulations with professional DNA.</p>

                <p>Our sole professional motto is to penetrate every corner to cater the service with professionalism.</p>
            </div>
        </section>
    )
}

export default OurVision