const Company = [
  {
    name: "krishna Associates",
    fullName: "krishna Associates",
    phone: {
      primary: [
        {
          label: "",
          icon: "mobile",
          number: "+91 7411752208",
        },
      ],
      alternate: [
        {
          label: "",
          icon: "receiver",
          number: "0831-4200637 / 4200638"
        }
      ],
    },
    website: "www.krishnaassociates.com",
    email: "krishna.associatesbgm@gmail.com",
    address: [
      "204, 2nd Floor, Aadi-Shakti Arcade, Ansurkar Lane, Near Nucleus Mall, Belgaum-590001",
      "204, 2nd Floor,Aadi-Shakti Arcade,Ansurkar Lane,Near Nucleus Mall,Belgaum-590001",
    ],
  },
];

const BankDetails = [
  {
    label: "Bank Details:",
    value: "Kotak Mahindra Bank",
  },
  {
    label: "Branch Name",
    value: "College Road Belgaum",
  },
  {
    label: "Account Name",
    value: "KRISHNA ASSOCIATES",
  },
  {
    label: "A/C. No.:",
    value: "0412955967",
  },
  {
    label: "A/C. Type",
    value: "Business Finance Current",
  },
  {
    label: "IFSC Code:",
    value: "KKBK0008227",
  },
];

export { Company, BankDetails };