import React from 'react'

function LogoCarousel({ AllCompanyLogos }) {
  return (
    <div className="scroller py-0">
        <ul className="scroller_inner">
          {
            AllCompanyLogos.map((item, index)=>(
              <li key={index}>
                <img src={item} alt="client logos" />
              </li>
            ))
          }
        </ul>
        
    </div>
  )
}

export default LogoCarousel