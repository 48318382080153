import React, { useState } from "react";

import img1 from "../../Images/slider2/1.jpg";
import img2 from "../../Images/slider2/2.jpg";
import img3 from "../../Images/slider2/3.jpg";
import img4 from "../../Images/slider2/4.jpg";
import img5 from "../../Images/slider2/5.jpg";
import img6 from "../../Images/slider2/6.jpg";

import Slider from "../Home/components/Slider";

const AboutUs = () => {
  console.log("About Us");

  const [allImages, setAllImages] = useState([
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
  ]);

  return (
  <section className="about-us-section container p-0 pb-5">
    <div className="col-md-12 p-0 w-100">
      <Slider allImages={allImages} height={300} />
    </div>

    <div className="px-2">
      <h1 className="mt-5 mb-4">ABOUT US</h1>
      <p>Founded in November 2014, <b>Krishna Associates</b> is professionally managed pharmaceutical distributors based in Belgaum (Karnataka) having stockistship of more than 200 companies/divisions providing service to 1800 chemists / Institutions in Karnataka, Goa & Maharashtra. <b>Krishna Associates</b> has a basket of around 7000 brands/formulations covering all major segments including Antibiotics, Anesthetics, Anti-HIV, Cardio-Diabetic, Derma, Hormones, Large Volume Parenteral-IV Fluids, Neuropsychiatry, Nutrition, Parenteral Nutrition, Pediatrics, Schedule-X-Narcotics and Vaccines etc.</p>

      <p><b>Krishna Associates</b> is also Super Stockist of Anthem Biopharma Pvt. Ltd. for North Karnataka, Goa & Maharashtra, Consignee Agent of Eurolife Healthcare Ltd (Baxter IV) for North Karnataka & Goa and Stockist of Fresenius Kabi India Pvt Ltd (Speciality IV Drugs) for North Karnataka.</p>

      <p>Dr. Deepak Mali, founder of <b>Krishna Associates</b> is the youngest self-made first generation entrepreneur. It is under Dr. Deepak Mali’s dynamic and visionary leadership that in such a short span of time, <b>Krishna Associates</b> has emerged as one of the fastest growing Pharmaceutical Distributors in Karnataka.</p>

      <p>Dr. Deepak Mali has completed his M. Pharm in Medicinal Chemistry-Drug Design & Doctorate in Pharmaceutical Sciences from KLE University, Belgaum with his research work in Breast Cancer and Targeted Drug Delivery System. He is one of the experienced leaders of the Indian Pharmaceutical academic landscape with over 25 years of Industry/ Academic/ Research experience. He has also pursued his passion for Medical Education and completed MHPE from KLE University under MOU of University of Illinois at Chicago-USA.</p>
    </div>
  </section>
  );
};

export default AboutUs;
