import { Link } from "react-router-dom";
import "./App.scss";
import "./components/customer/Customer.css";
import TopHeader from "./snippets/TopHeader";

export default function Header() {
  return (
    <>
      <div className="product_header">
        <TopHeader />
      </div>
      <div className="lists">
        <Link
          to="/CompaniesList"
          style={{ textDecoration: "none", marginLeft: "30px" }}
        >
          <li>Company List</li>
        </Link>

        <Link to="/AllProducts" style={{ textDecoration: "none" }}>
          <li>Products</li>
        </Link>

        <Link to="/New_Product" style={{ textDecoration: "none" }}>
          <li>New Products</li>
        </Link>
        {/* <Link to="/All_P" style={{ textDecoration: "none" }}><li>Products List</li></Link> */}
      </div>
    </>
  );
}
