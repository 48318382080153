import React, { useState } from "react";
import Slider from "../Home/components/Slider";
import img1 from "../../Images/slider2/1.jpg";
import img2 from "../../Images/slider2/2.jpg";
import img3 from "../../Images/slider2/3.jpg";
import img4 from "../../Images/slider2/4.jpg";
import img5 from "../../Images/slider2/5.jpg";
import img6 from "../../Images/slider2/6.jpg";

const OurValues = () => {

    const [allImages, setAllImages] = useState([
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
    ]);

    return (
        <section className="container p-0 pb-5">
            <div className="col-md-12 p-0 w-100">
                <Slider allImages={allImages} height={300} />
            </div>

            <div className="px-2">
                <h1 className="mt-5 mb-4">OUR VALUES</h1>

                <p>Our values serve as a compass for our actions and describe how we behave in the world. It is the fundamental part of our day-to-day operations and everything we deliver against it as a common and single minded vision. It helps us to find the right focus for our work and our attitude towards our customers.</p>

                <ul>
                    <li><u>Dedication</u> - to our people.</li>
                    <li><u>Respect</u> - of all individuals with whom we have contact.</li>
                    <li><u>Collaboration</u> - opportunities are best realized when working together, with each other.</li>
                    <li><u>Entrepreneurship</u> - identifying and embracing opportunities and making those things happen.</li>
                    <li><u>Professional Excellence</u> - raising the bar in all we do so that no opportunity is wasted.</li>
                    <li><u>Creative Thinking</u> - always looking at the bigger picture and seeking out original opportunities that create value.</li>
                </ul>
            </div>
        </section>
    )
}

export default OurValues